@import "./../styles/variables.scss";

// Global styles for the body
body {
    background-image: url('/assets/bg.png');
    background-size: cover; // Makes sure the image covers the entire body
    background-position: center; // Centers the image
    background-repeat: no-repeat; // Prevents the image from repeating
    height: 100vh; // Ensures the body takes up the full viewport height
    margin: 0; // Resets margin to avoid spacing issues
    padding-top: $headerHeight;
}

.container {
    height: calc(100vh - #{$headerHeight});
    background: $bg;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        padding: 1.5rem 2.5rem;
        background: linear-gradient(220deg, $primary, rgba($primary, 0.5));
        border-radius: $borderRadius calc(#{$borderRadius} * 10);
    }
}