.buttonContainerBack {
    margin-right: 20px;
    font-size: 0.8rem;
    background-color: rgba(71, 85, 105, 1);
    border-radius: 6px;
    color: white;
    min-height: 40px;
    min-width: 100px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.buttonContainerBack:hover {
    background-color: rgba(71, 85, 105, 1); /* Same as normal state */
    color: white; /* Ensures text color doesn't change */
}

@media (min-width: 768px) {
    .buttonContainerBack {
        font-size: 0.8rem;
        margin-right: 800px;
    }
}

@media (min-width: 1800px) {
    .buttonContainerBack {
        margin-right: 1200px;
        font-size: 1.1rem;
    }
}