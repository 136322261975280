.footer {
  margin-top: 70px;
  bottom: 0; 
  left: 0; 
  right: 0; 
  background-color: #1F1F1F;
  padding: 20px 0; 
  z-index: 1000; 
}


.contact-button {
    background-color: #9C88FF;
    color: white;
    border-radius: 25px;
    padding: 15px 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #e56419; 
  }

  .send-button {
    background-color: #9C88FF;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    margin-Left:10px;
    width: 50%;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 0.6rem;
    border: none;
    cursor: pointer;
    margin-left: 50px;
    padding-top: 10rem;
    transition: background-color 0.3s ease;
  }
  
  .send-button:hover {
    background-color: #e56419; 
    color: white;
  }

  @media (max-width: 768px) {
    .send-button {
      width: 100%;
    }
  }

  .icon-container {
    border: 1px solid;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s; 
}

.icon-container a {
    color: inherit;
    font-size: 1.5rem;
    transition: color 0.3s; 
}

.icon-container:hover {
    background-color: #9C88FF; 
}

.icon-container:hover a {
    color: white; 
}


.rowClass{
  padding-top: 30px;
   padding-bottom: 30px;
   padding-left: 10rem
}

@media (max-width: 768px) {
  .rowClass {
    padding-left: 1rem;
    padding-right:1rem
  }
}

.divClass{
  display: flex;              
  justify-content: flex-start;
  align-items: flex-start;     
  height: 100%;   
}

@media (max-width: 768px) {
  .divClass {
    justify-content: center; 
  align-items: center; 
  }
}

.featuresContainer {
  display: flex;              
  justify-content: flex-start; 
  align-items: flex-start;     
  height: 100%;                
  flex-direction: column;     
}

@media (max-width: 768px) {
  .featuresContainer {
   padding-top: 2rem;
   padding-left: 1rem;
  }
}

.lastRow{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;    
  height: 100%;
  padding-Right: 10rem; 
}

@media (max-width: 768px) {
  .lastRow {
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;    
  padding-top: 20px;
  }
}

