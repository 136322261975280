@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

$Poppins: "Poppins", sans-serif;

// colors
$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 100px;

@mixin breakpoint($point) {
    @if $point == md {
        // Medium devices (≥ 768px)
        @media (min-width: 48em) {
            @content;
        }
    } @else if $point == sm {
        // Small devices (≤ 768px)
        @media (max-width: 48em) {
            @content;
        }
    }
}