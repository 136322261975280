body {
  background-color:#F5F6FA;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'poppins'; /* Name of the font */
  src: url('./assets/fonts/poppins.ttf') format('truetype'); /* Path to the font file */
  font-weight: normal; /* You can specify the font weight */
  font-style: normal; /* You can specify the font style */
}