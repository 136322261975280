.nav-bar {
    background-color: white;
    border-radius: 6px;
}

.first-page-row {
    background-color: white;
    border-radius: 6px;

}


.first-row {
    background-color: white;
    border-radius: 6px;
    min-height: 26rem;
}


@media (min-width: 1800px) {
    .first-row {
        min-height: 36rem;
    }
}

.imageWidth {
    width: 15px;
}


@media (min-width: 1800px) {
    .imageWidth {
        width: 25px;
    }
}

.margin {
    margin-bottom: 1rem;
}

@media (min-width: 1800px) {
    .margin {
        margin-bottom: 2rem;
    }
}

.margin10px {
    margin-bottom: 10px;
}

@media (min-width: 1800px) {
    .margin10px {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.plusImage {
    width: 1rem;
}


@media (min-width: 1800px) {
    .plusImage {
        width: 1.3rem;
    }
}



.image {
    height: 6rem;
}

@media (min-width: 1800px) {
    .image {
        height: 9.5rem;
    }
}

.text {
    font-size: 0.8rem;
}

@media (min-width: 1800px) {
    .text {
        font-size: 1.1rem;
    }
}

.text2 {
    font-size: 0.8rem;
    min-height: 40px;
}

@media (min-width: 1800px) {
    .text2 {
        font-size: 1.2rem;
        min-height: 60px;
    }
}


.heading {
    font-size: 1rem;
}

@media (min-width: 1800px) {
    .heading {
        font-size: 1.2rem;
    }
}

.inputBox {
    height: 1.5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .inputBox {
        height: 2.5rem;
        font-size: 1rem;
    }
}

.inputBoxSelect {
    height: 2rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .inputBoxSelect {
        height: 3rem;
        font-size: 1rem;
    }
}

/* For screens wider than 768px */
@media (min-width: 768px) {
    .rowClass {
        margin-top: 10px;
    }
}

/* For screens wider than 1800px */
@media (min-width: 1800px) {
    .rowClass {
        margin-top: 22px;
    }
}

.inputBoxDiv {
    height: 2rem;

}

@media (min-width: 1800px) {
    .inputBoxDiv {
        height: 3rem;

    }
}

.textBox {
    height: 9rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox {
        height: 15rem;
        font-size: 1rem;
    }
}

@media (min-width: 1800px) {
    .textBox {
        height: 15rem;
        font-size: 0.7rem;
    }
}

.textBoxIot {
    height: 4.6rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBoxIot {
        height: 8.5rem;
        font-size: 1rem;
    }
}

.textBoxIot2 {
    height: 3.5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBoxIot2 {
        height: 6rem;
        font-size: 1rem;
    }
}

.textBox2 {
    height: 2.5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox2 {
        height: 6rem;
        font-size: 1rem;
    }
}


.textBox3 {
    height: 2.5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox3 {
        height: 8.5rem;
        font-size: 1rem;
    }
}


.textBox4 {
    height: 5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox4 {
        height: 8rem;
        font-size: 1rem;
    }
}

.textBox10 {
    height: 6rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox10 {
        height: 10rem;
        font-size: 1rem;
    }
}


.textBox11 {
    height: 10rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox11 {
        height: 14rem;
        font-size: 1rem;
    }
}


.textBox5 {
    height: 2.5rem;
    font-size: 0.7rem;
}

@media (min-width: 1800px) {
    .textBox5 {
        height: 4rem;
        font-size: 1rem;
    }
}


.button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    /* Center by default */
}

@media (min-width: 768px) {
    .button-container {
        justify-content: flex-end;
        /* Right align on medium devices */
    }
}



.error_checked {
    box-shadow: 0 0 0 2px red;
    border-radius: 50px;
}

.margin-md {
    margin-top: 0;
}

@media (min-width: 768px) {
    .margin-md {
        margin-top: 3rem;
    }
}

