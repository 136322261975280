.topic1{
    display:flex;
    justify-content:center;
    align-content:center;
    color: #657081;
    font-weight: 700;
    font-size: 1rem;
}

@media (min-width: 768px) {
    .topic1 {
        font-size: 1.3rem;
    }
  }
  

  .topic2{
    display:flex;
    justify-content:center;
    align-content:center;
    color: #657081;
    font-weight: 700;
    font-size: 0.7rem;
}

@media (min-width: 768px) {
    .topic2 {
        font-size: 1rem;
    }
  }
  